import discordImage from '../images/discord.jpg';
import NackaImage from '../images/nackademin.jpg';
import AnzrImage from '../images/anzr.jpg';
import BmisImage from '../images/bmis.jpg';
import PlatconImage from '../images/platcon.jpg';
import KomvuxImage from '../images/komvux.jpg';

import TerraformImage from '../images/certs/Terraform.png';
import CDLImage from '../images/certs/CDL.png';
import ACEImage from '../images/certs/ACE.png';
import PCAImage from '../images/certs/PCA.png';
import PCDEVOPSEImage from '../images/certs/PCDEVOPSE.png';
import PCDImage from '../images/certs/PCD.png';
import PCNEImage from '../images/certs/PCNE.png';
import PCSEImage from '../images/certs/PCSE.png';
import PGWAImage from '../images/certs/PGWA.png';
import PDEImage from '../images/certs/PDE.png';
import PCDATABASEImage from '../images/certs/PCDATABASEE.png';
import PMLEImage from '../images/certs/PMLE.png';

export const Bio = {
	name: "Fredrik Magnusson",
	roles: [
		"upcoming Cloud Engineer",
		"aspiring DevOps Engineer",
	],
	description: `I am a motivated and versatile individual, always eager to take on new challenges.
                With a passion for learning I am dedicated to delivering high-quality results. With a
                positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things.`,
	github: "https://github.com/mindriddler",
	resume:
		"https://drive.google.com/file/d/1VTn8copPG-9FFxqf4ddnx8ZgtgCv9vqf/view?usp=drive_link",
	linkedin: "https://www.linkedin.com/in/fredrik-m/",
	facebook: "https://www.facebook.com/fredrik.magnusson10/",
};


// Use the ID to sort the array in ascending order
export const proficiencies = [
	{
		id: 4,
		title: "Languages",
		proficiencies: [
			{ name: "Swedish", image: "https://em-content.zobj.net/source/apple/118/flag-for-sweden_1f1f8-1f1ea.png", },
			{ name: "English", image: "https://em-content.zobj.net/source/apple/118/flag-for-united-states_1f1fa-1f1f8.png", }
		]
	},

	{
		id: 0,
		title: "Programming & Scripting Languages",
		proficiencies: [
			{ name: "Python", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/python/python-original.svg" },
			{ name: "Java", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/java/java-original.svg" },
			{ name: "Bash", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/bash/bash-original.svg" },
			{ name: "PowerShell", image: "https://cdn.iconscout.com/icon/free/png-256/powershell-2-569195.png" },
			{ name: "SQL", image: "https://cdn.iconscout.com/icon/free/png-256/sql-4-190807.png" },
			{ name: "Markdown", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/markdown/markdown-original.svg" },
		]
	},

	{
		id: 1,
		title: "Cloud, Infrastructure & Orchestration",
		proficiencies: [
			{ name: "Docker", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/docker/docker-original.svg" },
			{ name: "Terraform", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/terraform/terraform-original.svg" },
			{ name: "Ansible", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/ansible/ansible-original.svg" },
			{ name: "Microsoft Azure", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/azure/azure-original.svg" },
			{ name: "Kubernetes", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/kubernetes/kubernetes-plain.svg" },
			{ name: "Google Cloud Platform", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/googlecloud/googlecloud-original.svg" },
		]
	},

	{
		id: 3,
		title: "Development & Version Control Tools",
		proficiencies: [
			{ name: "Git", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/git/git-original.svg" },
			{ name: "GitHub", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/github/github-original.svg" },
			{ name: "VSCode", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/vscode/vscode-original.svg" },
		]
	},

	{
		id: 5,
		title: "Operating Systems",
		proficiencies: [
			{ name: "Linux", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/linux/linux-original.svg" },
			{ name: "Windows", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/windows8/windows8-original.svg" },
			{ name: "macOS", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/apple/apple-original.svg" },
		]
	},
	{
		id: 2,
		title: "Databases",
		proficiencies: [
			{ name: "MySQL", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/mysql/mysql-original.svg", },
			{ name: "MariaDB", image: "https://cdn.iconscout.com/icon/free/png-256/mariadb-282507.png", },
			{ name: "SQLite", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/sqlite/sqlite-original.svg", },
			{ name: "PostgreSQL", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/postgresql/postgresql-original.svg", },
			{ name: "MongoDB", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/mongodb/mongodb-original.svg", },
			{ name: "Microsoft SQL Server", image: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/microsoftsqlserver/microsoftsqlserver-plain.svg", },
		]
	},
];


export const certifications = [
	{
		id: 0,
		img: PMLEImage,
		name: "Professional Machine Learning Engineer",
		provider: "Google Cloud",
		date: "February 2024 - February 2026",
		doc: "",
		desc: `
					<p>
					A Professional Machine Learning Engineer builds, evaluates, productionizes, and optimizes ML models by using Google Cloud technologies 
					and knowledge of proven models and techniques. The ML Engineer handles large, complex datasets and creates repeatable, reusable code. 
					The ML Engineer considers responsible AI and fairness throughout the ML model development process, and collaborates closely with other job 
					roles to ensure long-term success of ML-based applications. The ML Engineer has strong programming skills and experience with data platforms 
					and distributed data processing tools. The ML Engineer is proficient in the areas of model architecture, data and ML pipeline creation, and 
					metrics interpretation. The ML Engineer is familiar with foundational concepts of MLOps, application development, infrastructure management, 
					data engineering, and data governance. The ML Engineer makes ML accessible and enables teams across the organization. By training, retraining, 
					deploying, scheduling, monitoring, and improving models, the ML Engineer designs and creates scalable, performant solutions.
					</p>
					<br>
						The Professional Machine Learning Engineer exam assesses your ability to:
					<ul>
						<li>Architect low-code ML solutions</li>
						<li>Collaborate within and across teams to manage data and models</li>
						<li>Scale prototypes into ML models</li>
						<li>Serve and scale models</li>
						<li>Automate and orchestrate ML pipelines</li>
						<li>Monitor ML solutions</li>
					</ul>
					`
	},
	{
		id: 1,
		img: PDEImage,
		name: "Professional Data Engineer",
		provider: "Google Cloud",
		date: "January 2024 - January 2026",
		doc: "https://google.accredible.com/4f2100ed-4677-404a-9304-4ac4cf8ceca4",
		desc: `
					<p>
					A Professional Data Engineer enables data-driven decision making by collecting, transforming, and publishing data. 
					A data engineer should be able to design, build, operationalize, secure, and monitor data processing systems with 
					a particular emphasis on security and compliance; scalability and efficiency; reliability and fidelity; and flexibility 
					and portability. A Data Engineer should also be able to leverage, deploy, and continuously train pre-existing machine learning models.
					</p>
					<br>
						The Professional Data Engineer exam assesses your ability to:
					<ul>
						<li>Design data processing systems</li>
						<li>Ingest and process the data</li>
						<li>Store the data</li>
						<li>Prepare and use data for analysis</li>
						<li>Maintain and automate data workloads</li>
					</ul>
					`
	},
	{
		id: 2,
		img: PCDATABASEImage,
		name: "Professional Cloud Database Engineer",
		provider: "Google Cloud",
		date: "January 2024 - January 2026",
		doc: "https://google.accredible.com/ea9432e8-e851-4944-b401-30bca59f00fc",
		desc: `
          <p>
          A Professional Cloud Database Engineer is a database professional with two years of Google Cloud experience and five years of 
          overall database and IT experience. The Professional Cloud Database Engineer designs, creates, manages, and troubleshoots 
          Google Cloud databases used by applications to store and retrieve data. The Professional Cloud Database Engineer should be 
          comfortable translating business and technical requirements into scalable and cost-effective database solutions.
          </p>
          <br>
            The Professional Cloud Database Engineer exam assesses your ability to:
          <ul>
            <li>Design scalable and highly available cloud database solutions</li>
            <li>Manage a solution that can span multiple database solutions</li>
            <li>Migrate data solutions</li>
            <li>Deploy scalable and highly available databases in Google Cloud</li>
          </ul>
          `
	},
	{
		id: 3,
		img: PGWAImage,
		name: "Professional Google Workspace Administrator",
		provider: "Google Cloud",
		date: "January 2024 - January 2026",
		doc: "https://google.accredible.com/d8ecb3c2-d742-4c8c-b21e-720e719c3cdb",
		desc: `
          <p>
            A Professional Google Workspace Administrator transforms business objectives into tangible configurations, policies, and security practices 
            as they relate to users, content, and integrations. Through their understanding of their organization's infrastructure, Google Workspace 
            Administrators enable people to work together, communicate, and access data in a secure and efficient manner. Operating with an engineering 
            and solutions mindset, they use tools, programming languages, and APIs to automate workflows. They look for opportunities to educate end 
            users and increase operational efficiency while advocating for Google Workspace and the Google toolset.
          </p>
          <br>
            The Professional Google Workspace Administrator exam assesses your ability to:
          <ul>
            <li>Plan and implement Google Workspace authorization and access</li>
            <li>Manage user, resource, and shared drive life cycles</li>
            <li>Control and configure Google Workspace services</li>
            <li>Configure and manage endpoint access</li>
            <li>Monitor organizational operations</li>
            <li>Advance Google Workspace adoption and collaboration</li>
          </ul>
          `
	},
	{
		id: 4,
		img: PCSEImage,
		name: "Professional Cloud Security Engineer",
		provider: "Google Cloud",
		date: "January 2024 - January 2026",
		doc: "https://google.accredible.com/cfc68f46-5d74-495d-aed4-07501dc9c1d6",
		desc: `
          <p>
            A Cloud Security Engineer enables organizations to design and implement secure workloads and infrastructure on Google Cloud. 
            Through an understanding of security best practices and industry security requirements, this individual designs, develops, and manages 
            a secure infrastructure by leveraging Google security technologies. The Cloud Security Engineer should be proficient in all aspects of 
            cloud Security including identity and access management, defining organizational structure and policies, using Google technologies to 
            provide data protection, configuring network security defenses, collecting and analyzing Google Cloud logs, managing incident responses, 
            and demonstrating an understanding of the application of dynamic regulatory considerations.
          </p>
          <br>
            The Professional Cloud Security Engineer exam assesses your ability to:
          <ul>
            <li>Configure access within a cloud solution environment</li>
            <li>Configure network security</li>
            <li>Ensure data protection</li>
            <li>Manage operations within a cloud solution environment</li>
            <li>Ensure compliance</li>
          </ul>
          `
	},
	{
		id: 5,
		img: PCNEImage,
		name: "Professional Cloud Network Engineer",
		provider: "Google Cloud",
		date: "December 2023 - December 2025",
		doc: "https://google.accredible.com/7b39b2a0-d978-4dc9-bea1-2f8e7cc3da03",
		desc: `
          <p>
            A Professional Cloud Network Engineer implements and manages network architectures in Google Cloud. 
            This individual may work on networking or cloud teams with architects who design cloud infrastructure. 
            The Cloud Network Engineer uses the Google Cloud Console and/ or command line interface, and leverages experience with network services,
            application and container networking, hybrid and multi - cloud connectivity, implementing VPCs, and security for established network 
            architectures to ensure successful cloud implementations.
          </p>
          <br>
            The Professional Cloud Network Engineer exam assesses your ability to:
          <ul>
            <li>Design, plan, and prototype a Google Cloud network</li>
            <li>Implement Virtual Public Cloud (VPC) instances</li>
            <li>Configure network services</li>
            <li>Implement hybrid interconnectivity</li>
            <li>Manage, monitor, and optimize network operations</li>
          </ul>
          `
	},
	{
		id: 6,
		img: PCDImage,
		name: "Professional Cloud Developer",
		provider: "Google Cloud",
		date: "December 2023 - December 2025",
		doc: "https://google.accredible.com/5ed79c37-3332-442d-8f69-def01a706d1c",
		desc: `
          <p>
            A Professional Cloud Developer builds scalable and highly available applications using Google- recommended
            tools and best practices.This individual has experience with cloud - native applications, developer tools, managed
            services, and next - generation databases.A Professional Cloud Developer also has proficiency with at least one general - purpose
            programming language and instruments their code to produce metrics, logs, and traces.
          </p>
          <br>
            The Professional Cloud Developer exam assesses your ability to:
          <ul>
            <li>Design highly scalable, available, and reliable cloud-native applications</li>
            <li>Build and test applications</li>
            <li>Deploy applications</li>
            <li>Integrate Google Cloud services</li>
            <li>Manage deployed applications</li>
          </ul>
          `
	},
	{
		id: 7,
		img: PCDEVOPSEImage,
		name: "Professional Cloud DevOps Engineer",
		provider: "Google Cloud",
		date: "December 2023 - December 2025",
		doc: "https://google.accredible.com/422cc724-bc7d-4b33-9e36-3c9abbe3769f",
		desc: `
          <p>
            Professional Cloud DevOps Engineers implement processes throughout the systems development lifecycle
            using Google- recommended methodologies and tools.They build and deploy software and infrastructure delivery pipelines,
            optimize and maintain production systems and services, and balance service reliability with delivery speed.
          </p>
          <br>
            The Professional Cloud DevOps Engineer exam assesses your ability to:
          <ul>
            <li>Bootstrap a Google Cloud organization for DevOps</li>
            <li>Apply site reliability engineering principles to a service</li>
            <li>Optimize service performance</li>
            <li>Build and implement CI/CD pipelines for a service</li>
            <li>Implement service monitoring strategies</li>
          </ul>
          `
	},
	{
		id: 8,
		img: PCAImage,
		name: "Professional Cloud Architect",
		provider: "Google Cloud",
		date: "November 2023 - November 2025",
		doc: "https://google.accredible.com/41964977-f869-4726-93d0-182e54261d43",
		desc: `
          <p>
            Professional Cloud Architect enables organizations to leverage
            Google Cloud technologies.Through an understanding of cloud architecture and Google technology,
            this individual can design, develop, and manage robust, secure, scalable, highly available, and
            dynamic solutions to drive business objectives.
          </p>
          <br>
            A Google Cloud Certified - Professional Cloud Architect has demonstrated in our assessment their ability to:
          <ul>
            <li>Design and plan a cloud solution architecture</li>
            <li>Manage and provision the cloud solution infrastructure</li>
            <li>Design for security and compliance</li>
            <li>Analyze and optimize technical and business processes</li>
            <li>Manage implementations of cloud architecture</li>
            <li>Ensure solution and operations reliability</li>
          </ul>
          `
	},
	{
		id: 9,
		img: ACEImage,
		name: "Associate Cloud Engineer",
		provider: "Google Cloud",
		date: "October 2023 - October 2026",
		doc: "https://google.accredible.com/64f0fb14-bbff-4162-b770-2b7dd8f1c586",
		desc: `
          <p>
          An Associate Cloud Engineer deploys applications, monitors operations, and manages enterprise
          solutions. This individual is able to use Google Cloud Console and the command-line interface
          to perform common platform-based tasks to maintain one or more deployed solutions that leverage
          Google-managed or self-managed services on Google Cloud.
          </p>
          <br>
            The Associate Cloud Engineer exam assesses your ability to:
          <ul>
            <li>Set up a cloud solution environment</li>
            <li>Plan and configure a cloud solution</li>
            <li>Deploy and implement a cloud solution</li>
            <li>Ensure successful operation of a cloud solution</li>
            <li>Configure access and security</li>
          </ul>
          `
	},
	{
		id: 10,
		img: TerraformImage,
		name: "Terraform Associate (003)",
		provider: "HashiCorp",
		date: "August 2023 - August 2025",
		doc: "https://www.credly.com/badges/e2eb1846-4eb6-4d2d-ab6c-69b677d0f57e",
		desc: `
          Earners of the HashiCorp Certified: Terraform Associate certification
          know the basic concepts, skills, and use cases associated with open source
          HashiCorp Terraform. They understand and can utilize Terraform according to
          the certification objectives. Additionally, they understand why enterprises
          choose to extend Terraform Open Source with Terraform Enterprise to solve
          business critical objectives.
          `
	},
	{
		id: 11,
		img: CDLImage,
		name: "Cloud Digital Leader",
		provider: "Google Cloud",
		date: "December 2023 - December 2026",
		doc: "https://www.credential.net/224c4c88-56b6-414c-a2ae-232f117e1506",
		desc: `
          A successful candidate can articulate the capabilities of Google Cloud
          core products and services and how they benefit organizations. The candidate
          can also describe common business use cases and how cloud solutions support an enterprise.
          `
	}
];

export const experiences = [
	{
		id: 0,
		img: "https://upload.wikimedia.org/wikipedia/en/8/83/DevoteamLogo.jpg",
		role: "Cloud Engineer",
		company: "Devoteam G Cloud Sweden",
		date: "March 2024 - Ongoing",
		desc: `
		  As a Cloud Engineer at Devoteam G Cloud Sweden, I am responsible for
		  designing, developing, and maintaining cloud infrastructure for our
		  clients. My role involves collaborating with cross-functional teams to
		  deliver scalable and reliable cloud solutions. I also work on automating
		  infrastructure provisioning, monitoring, and security.
		  `,
		proficiencies: [
			"CI/CD",
			"Terraform",
			"GitHub Actions",
			"GCP",
			"Python",
			"Agile",
			"Kubernetes"
		],
		doc: "https://se.devoteam.com/",
	},
	{
		id: 1,
		img: "https://upload.wikimedia.org/wikipedia/en/8/83/DevoteamLogo.jpg",
		role: "Cloud Engineer Intern",
		company: "Devoteam G Cloud Sweden",
		date: "October 2023 - February 2024",
		desc: `
          As my internship at Devoteam G Cloud is ongoing i have so far taken part in
          a few projects. One of the projects i have been involved in is a project
          where we are creating a CI/CD pipeline for a customer. The pipeline is
          built using Terraform and GitHub Actions. The pipeline is used to deploy
          several maven modules to Cloud Functions.
          `,
		proficiencies: [
			"CI/CD",
			"Terraform",
			"GitHub Actions",
			"Cloud Functions",
			"GCP",
			"Python",
			"Java",
			"Maven",
		],
		doc: "https://se.devoteam.com/",
	},
	{
		id: 2,
		img: AnzrImage,
		role: "DevSecOps Engineer",
		company: "Anzr",
		date: "January 2023 - May 2023",
		desc: `
          During my tenure as a DevSecOps Engineer at Anzr, I was responsible for
          ensuring that security was seamlessly integrated throughout the entire software
          development life cycle. My role involved creating automated security checks and
          tests, managing access controls and permissions, and monitoring system logs for
          security incidents.
          `,
		proficiencies: [
			"Docker",
			"Python",
			"Postgresql",
			"Git",
			"GitHub",
			"VS Code",
			"Logging",
			"Agile",
			"CI/CD",
		],
		doc: "https://anzr.co/",
	},
	{
		id: 3,
		img: "https://seeklogo.com/images/H/Hornbach_Holding-logo-DE242F073A-seeklogo.com.png",
		role: "Store Employee",
		company: "Hornbach Byggmarknad",
		date: "November 2019 - Ongoing (100% on leave)",
		desc: `
          As a store employee at Hornbach in Norsborg, I have a central role in the
          process that ensures that store goods, including customer orders, reach our store
          shelves and customers efficiently. This process involves receiving incoming goods
          and scrutinizing them to ensure quality and correct quantity. Furthermore, I am
          responsible for ensuring that the product flow from our warehouse continuously
          flows smoothly out to the store's various departments. I also handle returns and
          complaints, which requires both accuracy and good customer service. Finally, to
          guarantee an efficient supply of goods, I have regular contact with the store's
          suppliers.
          `,
		proficiencies: [
			"Inventory Management",
			"Attention to Detail",
			"Communication",
			"Customer Service",
			"Problem Solving",
			"Organizational Skills",
			"Time Management",
			"Technical Skills",
			"Negotiation",
			"Stress Management",
		],
		doc: "https://www.hornbach.se/",
	},
	{
		id: 4,
		img: "",
		role: "Maintenance and Care Worker",
		company: "L.M Service",
		date: "January 2019 - November 2019",
		desc: `
          At L.M Service, I undertook a wide range of responsibilities. A typical
          day could encompass tasks from lawn mowing, bridge renovation, and vandalism
          repair to the maintenance of municipal areas such as parks and playgrounds.
          I was also involved in the care and cementing of municipal benches.
          `,
		proficiencies: [
			"Lawn Maintenance",
			"Bridge Renovation",
			"Vandalism Repair",
			"Public Area Maintenance",
			"Cementing",
		],
		doc: "https://www.lm-service.se/",
	},
	{
		id: 5,
		img: BmisImage,
		role: "Carpenter Apprentice",
		company: "Byggmästar'n i Skåne",
		date: "August 2018 - January 2019",
		desc: `
          As part of Byggmästar'n i Skåne, I worked to complete my apprenticeship
          hours for my professional certificate. My responsibilities mainly revolved
          around contract carpentry. I joined the team post the structural phase and
          hence primarily dealt with the building's interior tasks. Daily activities
          involved tasks such as plasterboarding, insulation, kitchen installations,
          and framing.
          `,
		proficiencies: [
			"Contract Carpentry",
			"Plasterboarding",
			"Insulation",
			"Kitchen Installations",
			"Framing",
		],
		doc: "https://www.byggmastarn.nu/",
	},
	{
		id: 6,
		img: PlatconImage,
		role: "Construction Worker",
		company: "Platcon Byggnads AB",
		date: "March 2017- August 2018",
		desc: `
          At Platcon Byggnads AB, a firm specializing in moisture control,
          I engaged in various activities such as sill replacements, laying
          ventilated floors, pouring house slabs, facade works, and masonry.
          `,
		proficiencies: [
			"Moisture Control",
			"Sill Replacement",
			"Ventilated Floors",
			"House Slab Pouring",
			"Facade Works",
			"Masonry",
		],
		doc: "", // Not giving Platcon Byggnads AB any free advertising
	},
	{
		id: 7,
		img: "https://cdn.theorg.com/327c531c-9a26-4267-bd96-9cdbf43380cb_thumb.jpg",
		role: "Warehouse Worker",
		company: "Logent AB / Boozt.com",
		date: "2014 - 2017",
		desc: `
          During my employment at Logent AB, I was stationed at Boozt AB,
          ensuring customer orders were fulfilled. Having spent a significant time
          at Boozt, I had the opportunity to engage in various aspects of the
          process: from the receipt of goods at the warehouse to dispatching them
          for customer delivery. My responsibilities included picking, sorting,
          and packing orders, managing deliveries, inventory checks, handling
          returns, and processing incoming shipments.`,
		proficiencies: [
			"Order Fulfillment",
			"Inventory Checks",
			"Order Packing",
			"Goods Receipt",
			"Returns Management",
			"Product Sorting",
			"Delivery Management",
			"Customer Service",
			"Attention to Detail",
		],
		doc: "", // Not giving Logent AB or Boozt.com any free advertising
	},

];

export const education = [
	{
		id: 0,
		img: NackaImage,
		school: "Nackademin",
		date: "August 2022 - May 2024",
		desc: `
          I recently grraduated after being enrolled in Nackademin's DevOps Engineer program,
          a two-year intensive course emphasizing the combined roles of software
          development and IT operations. The program is designed to elevate the
          value of IT delivery by fostering a swift and efficient transition from
          requirements specification to operational service. It imparts skills in
          both programming and IT operations, covering everything from software
          design to production support and deployment. The education bridges the
          gap between the rapid evolution of development goals and the operational
          responsibility of ensuring systems perform optimally.`,
		degree: "Higher Vocational Education Diploma",
		proficiencies: [
			"System Design",
			"Python",
			"SQL",
			"Agile",
			"Java",
			"Windows Server",
			"Linux",
			"Networking",
			"Azure",
			"Ansible",
			"CI/CD",
			"IaC",
			"Docker",
			"Git",
			"GitHub",
			"VS Code",
		],
		url: "https://nackademin.se/utbildningar/devops-engineer/",
	},
	{
		id: 1,
		img: KomvuxImage,
		school: "Komvux Stockholm",
		date: "2021 - 2022",
		desc: `
          Completed courses in Mathematics (Matematik 2b) and Computer and Network
          Technology (Dator- och nätverksteknik) as part of my continuous learning
          endeavors.`,
		degree: "Course Completion",
		proficiencies: [
			"Mathematics",
			"Computer Technology",
			"Network Technology",
		],
		url: "https://komvux.stockholm.se/vux/vux/Start",
	},
	{
		id: 2,
		img: "https://uploads-ssl.webflow.com/60267e0e48dcb1a304e28cd0/616ee717f7a6ff4bbd0231e5_Ka%CC%88vlinge_vapen.svg",
		school: "Frans Möllergymnasiet",
		date: "August 2010 - June 2013",
		desc: `
          Enrolled in the Building Program with a focus on woodwork and carpentry.
          Acquired foundational knowledge and practical skills in woodworking,
          construction methods, and materials.`,
		degree: "Secondary Education Diploma",
		proficiencies: [
			"Woodworking",
			"Carpentry",
			"Construction Methods",
			"Materials Knowledge",
		],
		url: "",
	},
	{
		id: 3,
		img: "",
		school: "Enoch Thulin Gymnasiet",
		date: "August 2008 - June 2010",
		desc: `
          Pursued the Electrical Program with a specialization in computer
          technology. Gained insights into basic electrical concepts, components,
          and computer systems.`,
		degree: "Secondary Education Diploma",
		proficiencies: [
			"Electrical Concepts",
			"Computer Systems",
			"Electrical Components",
		],
		url: "",
	}
];


export const projects = [
	{
		id: 0,
		title: "Discord Bot",
		date: "April 2023 - Ongoing",
		desc: `
          Simple Discord Bot that can be used to show a schedule, get OpenAI GPT-4
          responses, and more.
          `,
		img: discordImage,
		tags: [
			"Docker",
			"Discord Py",
			"API",
			"OpenAI",
			"CI/CD",
		],
		category: "Personal Projects",
		github: "https://github.com/mindriddler/discord-bot",
		private: false,
	},
	{
		id: 1,
		title: "Terraform, Ansible CI/CD Pipeline",
		date: "September 2023",
		desc: `
          Part of my Linux 2 course at Nackademin. A CI/CD pipeline that uses
          Terraform to provision infrastructure on GCP and Ansible to deploy a
          wordpress application on the provisioned infrastructure.
          `,
		img: "https://www.parasoft.com/wp-content/uploads/2021/04/CICD_CICD.png",
		tags: [
			"Terraform",
			"Ansible",
			"GCP",
			"CI/CD",
		],
		category: "School Projects",
		github: "https://github.com/mindriddler/terraform_ansible_pipeline",
		private: false,
		member: [
			{
				name: "Fredrik Magnusson",
				img: "https://avatars.githubusercontent.com/u/112268732?v=4",
				linkedin: "https://www.linkedin.com/in/fredrik-m/",
				github: "https://github.com/mindriddler",
			},
			{
				name: "Alexandru Roman",
				img: "https://avatars.githubusercontent.com/u/112509308?v=4",
				linkedin: "https://www.linkedin.com/in/alex-roman-devops/",
				github: "https://github.com/AlexRoman777",
			},
			{
				name: "Saman Petfat",
				img: "https://avatars.githubusercontent.com/u/53537925?v=4",
				linkedin: "https://www.linkedin.com/in/saman-petfat/",
				github: "https://github.com/SamanPetfat",
			},
			{
				name: "Pavel Kostyuk",
				img: "https://avatars.githubusercontent.com/u/112487271?v=4",
				linkedin: "https://www.linkedin.com/in/pavel-kostyuk-710a521b8/",
				github: "https://github.com/PavelKostyuk",
			},
			{
				name: "Martin Alfredson",
				img: "https://avatars.githubusercontent.com/u/112489114?v=4",
				linkedin: "https://www.linkedin.com/in/martin-alfredson-750044275/",
				github: "https://github.com/maal2202",
			}
		],
	},
	{
		id: 2,
		title: "Jenkins CI/CD Pipeline",
		date: "November 2023",
		desc: `
          Part of my Continuous Integration and Continuous Delivery course at Nackademin.
          A CI/CD pipeline that uses Jenkins to lint, test and build a docker image and
          also a github action pipeline that do the same but also pushes the image to
          GitHub Container Registry.
          `,
		img: "https://loves.cloud/wp-content/uploads/2020/04/CICD-Pipeline-Using-Docker-And-Jenkins.jpg",
		tags: [
			"Jenkins",
			"Docker",
			"CI/CD",
			"GitHub Actions",
			"Testing",
		],
		category: "School Projects",
		github: "https://github.com/mindriddler/CI-CD_Jenkins_pipeline",
		private: false,
		member: [
			{
				name: "Fredrik Magnusson",
				img: "https://avatars.githubusercontent.com/u/112268732?v=4",
				linkedin: "https://www.linkedin.com/in/fredrik-m/",
				github: "https://github.com/mindriddler",
			},
			{
				name: "Alexandru Roman",
				img: "https://avatars.githubusercontent.com/u/112509308?v=4",
				linkedin: "https://www.linkedin.com/in/alex-roman-devops/",
				github: "https://github.com/AlexRoman777",
			},
			{
				name: "Saman Petfat",
				img: "https://avatars.githubusercontent.com/u/53537925?v=4",
				linkedin: "https://www.linkedin.com/in/saman-petfat/",
				github: "https://github.com/SamanPetfat",
			},
			{
				name: "Gustav Öberg",
				img: "https://avatars.githubusercontent.com/u/112485468?v=4",
				linkedin: "https://www.linkedin.com/in/gurraoberg96/",
				github: "https://github.com/gurraoberg",
			},
			{
				name: "Martin Alfredson",
				img: "https://avatars.githubusercontent.com/u/112489114?v=4",
				linkedin: "https://www.linkedin.com/in/martin-alfredson-750044275/",
				github: "https://github.com/maal2202",
			}
		],
	},
	{
		id: 3,
		title: "PC Setup",
		date: "August 2023 - Ongoing",
		desc: `
          Python project for quickly setting up a new PC. Customized
          mostly for my own needs but can be easily modified to fit other needs.
          `,
		img: "https://previews.123rf.com/images/myvector/myvector1305/myvector130500230/19616342-icon-depicting-a-software-installation-on-pc.jpg",
		tags: [
			"Python",
			"Automation",
			"Linux",
			"Windows",
		],
		category: "Personal Projects",
		github: "https://github.com/mindriddler/pc-setup",
		private: true,
	},
	{
		id: 4,
		title: "Nackademin | School Material",
		date: "August 2022 - Ongoing",
		desc: `
          A collection of all the material I have created during my time
          at Nackademin. Also contain educational material provided by Nackademin.
          `,
		img: NackaImage,
		tags: [
			"Python",
			"SQL",
			"Java",
			"Windows Server",
			"Linux",
			"Networking",
			"Azure",
			"Ansible",
			"CI/CD",
			"IaC",
			"Docker",
			"Git",
			"GitHub",
			"VS Code",
		],
		category: "School Projects",
		github: "https://github.com/mindriddler/Nackademin",
		private: false,
	},
/*	{
		id: 5,
		title: "Swedish Police API",
		date: "March 2023 - August 2023",
		desc: `
          A REST API that provides information about the Swedish Police's events and news.
          `,
		image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Polisen_vapen_bra.svg/1024px-Polisen_vapen_bra.svg.png",
		tags: [
			"Python",
			"API",
			"Police",
			"JSON",
			"REST",
			"CLI",
		],
		category: "Personal Projects",
		github: "https://github.com/mindriddler/Swedish-Police-Events",
		private: false,
}, */
];

export const TimelineData = [
	{ year: 2022, month: "August", date: 22, text: "Started my journey" },
	{ year: 2023, month: "January", date: 16, text: "Got Hired at Anzr" },
	{ year: 2023, month: "August", date: 1, text: "Got my first certification, Terraform Associate" },
	{ year: 2023, month: "October", date: 11, text: "Started my internship at Devoteam G Cloud" },
	{ year: 2023, month: "October", date: 18, text: "Got my second certification, Google Cloud Associate Cloud Engineer" },
	{ year: 2023, month: "November", date: 21, text: "Got my third certification, Google Cloud Professional Cloud Architect" },
	{ year: 2023, month: "December", date: 5, text: "Got my fourth certification, Google Cloud Professional Cloud DevOps Engineer" },
	{ year: 2023, month: "December", date: 11, text: "Got my fifth certification, Cloud Digital Leader" },
	{ year: 2023, month: "December", date: 13, text: "Got my sixth certification, Google Cloud Professional Cloud Developer" },
	{ year: 2023, month: "December", date: 19, text: "Got my seventh certification, Google Cloud Professional Cloud Network Engineer" },
	{ year: 2024, month: "December", date: 3, text: "Got my eighth certification, Google Cloud Professional Cloud Security Engineer" },
	{ year: 2024, month: "January", date: 10, text: "Got my ninth certification, Google Cloud Professional Cloud Database Engineer" },
	{ year: 2024, month: "January", date: 15, text: "Got my tenth certification, Google Cloud Professional Google Workspace Administrator" },
	{ year: 2024, month: "January", date: 29, text: "Got my eleventh certification, Google Cloud Professional Data Engineer" },
	{ year: 2024, month: "February", date: 5, text: "Got my twelfth certification, Google Cloud Professional Machine Learning Engineer" },
	{ year: 2024, month: "March", date: 4, text: "Got hired at Devoteam G Cloud Sweden" },
].map(event => ({
	title: `${event.month} ${event.date}, ${event.year}`,
	cardTitle: event.text,
	cardDetailedText: event.text,
}));
