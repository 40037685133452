import React from 'react'
import styled from 'styled-components'
import { trackGAEvent } from '../../utils/gaUtils';

const Document = styled.img`
    display: none;
    height: 70px;
    width: fit-content;
    background-color: #000;
    border-radius: 10px;
    &:hover{
        cursor: pointer;
        opacity: 0.8;
    }
`

const Description = styled.div`
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_primary + 99};
    margin-bottom: 10px;
    margin-top: 4px;
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`

const Span = styled.span`
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
`

const Card = styled.div`
    width: 600px;
    border-radius: 10px;
    box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
    padding: 12px 16px;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
    transition: all 0.3s ease-in-out;
    &:hover{
        box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
        transform: translateY(-5px);
    }
    @media only screen and (max-width: 768px){
        padding: 10px;
        gap: 8px;
        width: 300px;
    }

    &:hover ${Document}{
        display: flex;
    }

    &:hover ${Span}{
        overflow: visible;
        -webkit-line-clamp: unset;

    }

    border: 0.1px solid #854CE6;
    box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
    a {
        display: block; // This makes the anchor take the full size of its parent
        text-decoration: none; // Remove underline
        color: inherit; // So that text within the anchor keeps its color
    }
`

const Top = styled.div`
    width: 100%;
    display: flex;
    gap: 12px
`

const Image = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 10px;
    margin-top: 4px;
    @media only screen and (max-width: 768px){
        height: 40px;
    }
`

const Body = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`


const Date = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_secondary + 80};
    @media only screen and (max-width: 768px){
        font-size: 10px;
    }
`

const Name = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.text_primary + 99};
    @media only screen and (max-width: 768px){
        font-size: 14px;
    }
`

const Degree = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text_secondary + 99};
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`


const Proficiencies = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    margin-top: -10px;
`

const ItemWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`

const Proficiency = styled.div`
    font-size: 15px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_primary + 99};
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`


const EducationCard = ({ education }) => {

    // This function will be triggered when an education card is clicked
    const handleEducationClick = (schoolName) => {
        trackGAEvent('education_click', schoolName);
    }

    return (
        <Card>
            <a
                href={education.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleEducationClick(education.school)}>
                <Top style={{ alignItems: !education.img ? 'flex-start' : 'center' }}>
                    {education.img && <Image src={education.img} />}
                    <Body>
                        <Name>{education.school}</Name>
                        <Degree>{education.degree}</Degree>
                        <Date>{education.date}</Date>
                    </Body>
                </Top>
                <Description>
                    {education?.desc &&
                        <Span>{education?.desc}</Span>
                    }
                    {education?.proficiencies &&
                        <>
                            <br />
                            <Proficiencies>
                                <b>Proficiencies:</b>
                                <ItemWrapper>
                                    {education?.proficiencies?.map((proficiency, index) => (
                                        <Proficiency key={index}>• {proficiency}</Proficiency>
                                    ))}
                                </ItemWrapper>
                            </Proficiencies>
                        </>
                    }
                </Description>
            </a>
        </Card>
    )
}

export default EducationCard;