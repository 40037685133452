import React from 'react';
import styled from 'styled-components';
import { trackGAEvent } from '../../utils/gaUtils';

const Document = styled.img`
    display: none;
    height: 70px;
    width: fit-content;
    background-color: #000;
    border-radius: 10px;
    &:hover{
        cursor: pointer;
        opacity: 0.8;
    }
`

const Description = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  margin-bottom: 10px;
  margin-top: 4px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }

  ul {
    list-style: disc; /* Change to the desired list style */
    margin-left: 15px; /* Adjust the indentation as needed */
  }

  li {
    margin-left: 15px; /* Adjust the indentation as needed (same as ul) */
  }
`;

const Span = styled.span`
overflow: hidden;
display: -webkit-box;
max-width: 100%;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
text-overflow: ellipsis;
`

const Card = styled.div`
    width: 650px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    padding: 12px 16px;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
    transition: all 0.3s ease-in-out;
    &:hover{
        box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
        transform: translateY(-5px);
    }
    @media only screen and (max-width: 768px){
        padding: 10px;
        gap: 8px;
        width: 300px;
    }

    &:hover ${Document}{
        display: flex;
    }

    &:hover ${Span}{
        overflow: visible;
        -webkit-line-clamp: unset;

    }

    border: 0.1px solid #306EE8;
    box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
    a {
        display: block; // This makes the anchor take the full size of its parent
        text-decoration: none; // Remove underline
        color: inherit; // So that text within the anchor keeps its color
    }
`

const Top = styled.div`
    width: 100%;
    display: flex;
    gap: 12px
    flex-wrap: wrap;
`

const Image = styled.img`
    height: 150px;
    width: 150px;
    border-radius: 10px;
    margin-top: 5px;
    margin-right: 10px;
    @media only screen and (max-width: 768px){
        height: 40px;
    }
`

const Body = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column; 
`

const Date = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_secondary + 80};
    @media only screen and (max-width: 768px){
        font-size: 10px;
    }
`


const Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.text_primary + 99};
    @media only screen and (max-width: 768px){
        font-size: 14px;
    }
`

const Organization = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text_secondary + 99};
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`

const CertificationCard = ({ certification }) => {
    // This function will be triggered when a certification card is clicked
    const handleCertificationClick = (certName) => {
        trackGAEvent('certification_click', certName);
    }

    return (
        <Card>
            <a
                href={certification.doc}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleCertificationClick(certification.name)}
            >
                <Top>
                    <Image src={certification.img} />
                    <Body>
                        <Title>{certification.name}</Title>
                        <Organization>{certification.provider}</Organization>
                        <Date>{certification.date}</Date>
                        <Description>
                            {certification?.desc && (
                                <Span dangerouslySetInnerHTML={{ __html: certification?.desc }} />
                            )}
                        </Description>
                    </Body>
                </Top>
            </a>
        </Card>
    );
}

export default CertificationCard;