import React from 'react';
import styled from 'styled-components';
import { Chrono } from 'react-chrono';
import { TimelineData } from '../../data/constants'; // adjust the path as needed
import theme from '../../themes/default';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    padding: 40px 0px 80px 0px;
    @media (max-width: 960px) {
        padding: 0px;
    }
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1350px;
    padding: 80px 0;
    gap: 12px;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`;

const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

const Desc = styled.div`
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    color: ${({ theme }) => theme.text_secondary};
    @media (max-width: 768px) {
        margin-top: 12px;
        font-size: 16px;
    }
`;

const TimelineSection = styled.div`
    width: 100%;
    width: 73%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
`;

const index = () => {
  return (
    <Container id="timeline">
      <Wrapper>
        <Title>My Journey</Title>
        <Desc>
          Significant milestones and achievements throughout my career.
        </Desc>
        <TimelineSection>
          <Chrono
            showAllCardsHorizontal
            cardWidth={450}
            cardHeight={100}
            contentDetailsHeight={100}
            fontSizes={{
              title: "1rem"
            }}
            items={TimelineData.map(event => ({
              title: `${event.title}`,
              cardTitle: event.cardTitle,
              cardDetailedText: event.cardDetailedText,
            }))}
            mode="HORIZONTAL"
            theme={{
              primary: theme.colors.primary1,
              secondary: theme.colors.background1,
              cardBgColor: theme.colors.background1,
              cardForeColor: theme.colors.text1,
              titleColor: theme.colors.text1,
              titleBgColor: theme.colors.primary1,
            }}
            enableDarkToggle
            slideShow
          />
        </TimelineSection>
      </Wrapper>
    </Container>
  );
};

export default index;
