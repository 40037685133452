import { ThemeProvider } from "styled-components";
import { useState, useEffect } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js'
import Navbar from "./components/Navbar";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import HeroSection from "./components/HeroSection";
import About from "./components/About";
import Proficiencies from "./components/Proficiencies";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import Education from "./components/Education";
import ProjectDetails from "./components/ProjectDetails";
import styled from "styled-components";
import Certification from "./components/Certification/index.js";
import TimelineSection from './components/Timeline/index.js';

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`

// For the CardsContainer:
const CardsContainer = styled.div`
  display: flex;
  justify-content: center;  // Centers the CardsWrapper horizontally within CardsContainer
  width: 100%;              // Take the full width of the parent
`

// For the CardsWrapper:
const CardsWrapper = styled.div`

  display: flex;
  align-items: baseline;  // Align cards to the top
  justify-content: center;  // Center the cards horizontally when there's room
  flex-wrap: wrap;
  gap: 20px;
`




function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  console.log(openModal)
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router >
        <Navbar />
        <Body>
          <HeroSection />
          {/* <Wrapper>
            <TimelineSection />
          </Wrapper> */}
          <Wrapper>
            <CardsContainer>
              <CardsWrapper>
                <Certification />
                <Experience />
                <Education />
              </CardsWrapper>
            </CardsContainer>
          </Wrapper>
          <Wrapper>
            <Proficiencies />
            <Projects openModal={openModal} setOpenModal={setOpenModal} />
          </Wrapper>
          <Wrapper>
            <Contact />
            <Footer />
          </Wrapper>
          {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          }
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
